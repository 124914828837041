const itssUsers = [
  "edward.boswell@auctane.com",
  "robert.banh@auctane.com",
  "chris.collins@auctane.com",
  "jack.armstrong@auctane.com",
];

export const airtableSyncAllowList = [
  ...itssUsers,
  "sarah.holdgrafer@auctane.com",
  "sarah.wambold@auctane.com",
];

export const sendgridAllowList = [
  ...itssUsers,
  "david.bass@auctane.com",
  "david.cunningham@auctane.com",
  "lachhab.beaoui@auctane.com",
  "robert.hildom@auctane.com",
  "robert.klemme@auctane.com",
  "ryan.pesta@auctane.com",
  "amber.carns@auctane.com",
  "hannah.mcvay@auctane.com",
  "drew.mckey@auctane.com",
  "nestor.vasquez@auctane.com",
  "megan.skorzewski@auctane.com",
  "layne.watkins@auctane.com",
  "lee.cheney@auctane.com",
  "brian.mckeogh@auctane.com",
  "varsha.rajaram@auctane.com",
  "rob.mack@auctane.com",
  "anna.cantlon@auctane.com",
  "ramey.couey@auctane.com",
  "kidist.tesfaye@auctane.com",
  "angie.murphy@auctane.com",
  "josh.martin@auctane.com",
  "ellen.fite@auctane.com",
  "ash.short@auctane.com",
  "kristine.minnicino@auctane.com",
  "melinda.hughes@auctane.com",
  "catiria.rogers@auctane.com",
  "john.ison@auctane.com",
  "ben.rokke@auctane.com",
  "fiona.chow@auctane.com",
  "phillip.loesch@auctane.com",
  "david.doty@auctane.com",
  "anusha.uppuluri@auctane.com",
  "joe.mondile@auctance.com",
  "chris.bonk@auctane.com",
  "douglas.gutierrez@auctane.com",
  "angel.diaz@auctane.com",
  "paul.ramirez@auctane.com",
  "christopher.lun@auctane.com",
  "michael.haws@auctane.com",
  "ray.garza@auctane.com",
  "hector.quezada@auctane.com",
  "philipp.hesseler@auctane.com",
  "tony.rankin@auctane.com",
  "dre.graham@auctane.com",
  "robert.klemme@auctane.com",
  "jay.vichare@auctane.com",
  "jeremiah.wade@auctane.com",
  "katy.malapit@auctane.com",
  "becky.belisle@auctane.com",
  "andrew.wang@auctane.com",
  "ariane.kangabo@auctane.com",
];

export const wbrCreateReportAllowLists = [
  ...itssUsers,
  "brett.schulte@auctane.com",
  "jessee.billman@auctane.com",
  "robert.parnell@auctane.com",
  "jared.wiltshire@auctane.com",
  "javier.mur@auctane.com",
  "matthew.rothstein@auctane.com",
  "cindy.wu@auctane.com",
];
